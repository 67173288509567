import useStore from '../../../sharedCode/stores/store';
import useDndStore from '../../../sharedCode/stores/dndStore';
import { findGroup } from '../../../sharedCode/Utils/findDataFunctions';
import { v4 as uuidv4 } from 'uuid';
import usePersistStore from '../../../sharedCode/stores/persistStore';
import defaultEmojis from '../../Utils/defaultEmojis';
import { showToast } from '../../../sharedCode/Utils/showToastWrapper';
import {
    isNote,
    isTab,
    isTabNote
} from '../../../sharedCode/Utils/getItemType';
import layoutStore from '../../../sharedCode/stores/layoutStore';
import useSelectStore from '../../../sharedCode/stores/selectStore';

export const handleTabMove = (active) => {
    let { insertPosition, overContainerZu, activeItem } =
        useDndStore.getState();

    let originalContinerId = activeItem.originalGroupId;

    moveTabBetweenGroups(originalContinerId, insertPosition, overContainerZu);
};

const moveTabBetweenGroups = (
    originalContainerId,
    insertPosition,
    overContainerId
) => {
    const [overItemId, position] = Object.entries(insertPosition).flat();
    if (!overItemId) {
        return;
    }
    let tabGroups = structuredClone(useStore.getState().tabGroups);
    let originalContainer = findGroup(tabGroups, originalContainerId);
    let dragIds = useDndStore.getState().dragIds;
    const removedTabs = originalContainer.tabs.filter((tab) =>
        dragIds.includes(tab.id)
    );
    originalContainer.tabs = originalContainer.tabs.filter(
        (tab) => !dragIds.includes(tab.id)
    );

    if (position === 'center') {
        let result = handleCenterDrop(
            originalContainerId,
            overContainerId,
            tabGroups,
            originalContainer,
            overItemId,
            removedTabs
        );
        if (result.abort) {
            showToast('Drop not allowed', result.msg, 'warning');
            return;
        } else {
            useStore
                .getState()
                .updateTabGroups(
                    tabGroups,
                    { type: 'items', ids: [] },
                    true,
                    true,
                    500
                ); // TODO fixa id
            return;
        }
    }
    if (overContainerId === 'ContainerPlaceholder') {
        addGroupWithTabs(tabGroups, removedTabs);
        return;
    } else if (overContainerId === 'TrashDropZone') {
        useStore.getState().deleteItems(dragIds);
        return;
    } else if (originalContainerId === overContainerId) {
        // If moving within the same container,
        let insertIndex = originalContainer.tabs.findIndex(
            (tab) => tab.id === overItemId
        );

        if (position === 'under') {
            insertIndex++;
        }

        originalContainer.tabs.splice(insertIndex, 0, ...removedTabs);
    } else {
        // If moving to a different container
        let overContainer = findGroup(tabGroups, overContainerId);

        let insertIndex = 0;
        if (overContainer.tabs.length > 0) {
            insertIndex = overContainer.tabs.findIndex(
                (tab) => tab.id === overItemId
            );
            if (position === 'under') {
                insertIndex++;
            }
        }
        overContainer.tabs.splice(insertIndex, 0, ...removedTabs);
    }
    useStore
        .getState()
        .updateTabGroups(
            tabGroups,
            { type: 'items', ids: [] },
            true,
            true,
            500
        ); // TODO fixa id
    // setTabGroups(tabGroups);
};

const handleCenterDrop = (
    originalContainerId,
    overContainerId,
    tabGroups,
    originalContainer,
    overItemId,
    removedTabs
) => {
    let overContainer;
    if (originalContainerId === overContainerId) {
        overContainer = originalContainer;
    } else {
        overContainer = findGroup(tabGroups, overContainerId);
    }

    let insertIndex = overContainer.tabs.findIndex(
        (tab) => tab.id === overItemId
    );

    if (removedTabs.length === 1) {
        if (removedTabs[0].isStacked) {
            if (overContainer.tabs[insertIndex].comment.length) {
                return {
                    abort: true,
                    msg: 'Stacked items can not merge with notes.'
                };
            } else {
                let newStack = stackItems(
                    overContainer.tabs[insertIndex],
                    removedTabs[0].stackedItems
                );
                overContainer.tabs[insertIndex] = newStack;
            }
        } else if (isTabNote(removedTabs[0])) {
            if (isNote(overContainer.tabs[insertIndex])) {
                let newTab = removedTabs[0];
                newTab.comment =
                    removedTabs[0].comment +
                    '\n' +
                    overContainer.tabs[insertIndex].comment;
                overContainer.tabs[insertIndex] = newTab;
            } else {
                return {
                    abort: true,
                    msg: 'Combined site and note can only merge with other notes.'
                };
            }
        } else if (removedTabs[0].url?.length) {
            // Is tab
            if (overContainer.tabs[insertIndex].isStacked) {
                overContainer.tabs[insertIndex].stackedItems.push(
                    removedTabs[0]
                );
            } else if (isNote(overContainer.tabs[insertIndex])) {
                overContainer.tabs[insertIndex].url = removedTabs[0].url;
                overContainer.tabs[insertIndex].favIcon =
                    removedTabs[0].favIcon;
                overContainer.tabs[insertIndex].note = true;
            } else if (isTabNote(overContainer.tabs[insertIndex])) {
                return {
                    abort: true,
                    msg: 'Site cannot merge with combined site and note.'
                };
            } else {
                let newStack = stackItems(
                    overContainer.tabs[insertIndex],
                    removedTabs
                );
                overContainer.tabs[insertIndex] = newStack;
            }
        } else {
            //Is note
            if (!removedTabs[0].comment.length) {
                return {
                    abort: true,
                    msg: 'Something went wrong, please try again. Error 30'
                };
            }
            if (overContainer.tabs[insertIndex].isStacked) {
                return {
                    abort: true,
                    msg: 'Can not merge note with stacked itms.'
                };
            } else if (
                isNote(overContainer.tabs[insertIndex]) ||
                isTabNote(overContainer.tabs[insertIndex])
            ) {
                overContainer.tabs[insertIndex].comment =
                    overContainer.tabs[insertIndex].comment +
                    '\n' +
                    removedTabs[0].comment;
            } else if (isTab(overContainer.tabs[insertIndex])) {
                overContainer.tabs[insertIndex].comment =
                    removedTabs[0].comment;
                overContainer.tabs[insertIndex].commentColor =
                    removedTabs[0].commentColor;
                overContainer.tabs[insertIndex].todo = removedTabs[0].todo;
                overContainer.tabs[insertIndex].note = true;
            } else {
                return {
                    abort: true,
                    msg: 'Something went wrong, please try again. Error 31'
                };
            }
        }
    } else {
        let isStackedMoved = false;
        let isTabMoved = false;
        let isTabNoteMoved = false;
        let isNoteMoved = false;
        removedTabs.forEach((tab) => {
            if (tab.isStacked) {
                isStackedMoved = true;
            } else if (isNote(tab)) {
                isNoteMoved = true;
            } else if (isTab(tab)) {
                isTabMoved = true;
            } else if (isTabNote(tab)) {
                isTabNoteMoved = true;
            }
        });
        if (isNoteMoved && !isTabNoteMoved && !isTabMoved && !isStackedMoved) {
            //moving multiple notes.

            if (!overContainer.tabs[insertIndex].isStacked) {
                if (isTab(overContainer.tabs[insertIndex])) {
                    overContainer.tabs[insertIndex].note = true;
                }
                let allNotesCombined = '';
                removedTabs.forEach((tab) => {
                    allNotesCombined = allNotesCombined + '\n' + tab.comment;
                });
                overContainer.tabs[insertIndex].comment =
                    overContainer.tabs[insertIndex].comment +
                    '\n' +
                    allNotesCombined;
            } else {
                return {
                    abort: true,
                    msg: 'Can not merge notes with stacked items.'
                };
            }
        } else if (isNoteMoved) {
            //moving note including other items
            return {
                abort: true,
                msg: 'Can not merge multiple items that contains a note.'
            };
        } else if ((isStackedMoved || isTabMoved) && !isTabNoteMoved) {
            // Only tabs and stackes moved
            if (
                isTab(overContainer.tabs[insertIndex]) ||
                overContainer.tabs[insertIndex].isStacked
            ) {
                let allTabs = [];
                removedTabs.forEach((tab) => {
                    if (tab.isStacked) {
                        allTabs.push(...tab.stackedItems);
                    } else if (isTab(tab)) {
                        allTabs.push(tab);
                    }
                });
                if (overContainer.tabs[insertIndex].isStacked) {
                    overContainer.tabs[insertIndex].stackedItems.push(
                        ...allTabs
                    );
                } else {
                    let newStack = stackItems(
                        overContainer.tabs[insertIndex],
                        allTabs
                    );
                    overContainer.tabs[insertIndex] = newStack;
                }
            } else {
                return {
                    abort: true,
                    msg: 'Can only merge stacks or multiple tabs on other tabs'
                };
            }
        } else {
            return {
                abort: true,
                msg: 'Cannot merge selected items and item dropped on.'
            };
        }
    }
    return { abort: false };
};

export const stackItems = (overItem, tabsToAdd) => {
    if (overItem.isStacked) {
        overItem.stackedItems.push(...tabsToAdd);
        return overItem;
    } else {
        let newId = uuidv4();
        const dateCreated = new Date().toString();

        let newStack = {
            id: newId,
            isStacked: true,
            stackedItems: [overItem, ...tabsToAdd],
            dateCreated: dateCreated,
            comment: '',
            commentColor: false,
            title: ''
        };
        return newStack;
    }
};

export const addGroupWithTabs = async (
    tabGroups,
    movedTabs,
    title = 'Untitled',
    emoji = null
) => {
    let category = usePersistStore.getState().selectedCategory;
    let userId = useStore.getState().userData.userId;
    const currentCategoryID = useStore.getState().categories[category].id;
    const currentSlug = useStore.getState().categories[category].slug && {
        slug: useStore.getState().categories[category].slug
    };

    let fallbackState = structuredClone(tabGroups);
    let resp = { msg: 'error' };
    const groupsInCategorie = tabGroups.filter(
        (tabs) => tabs.categoryID === currentCategoryID
    ).length;
    if (groupsInCategorie < 16) {
        const dateCreated = new Date().toString();
        const newId = uuidv4();
        const len = defaultEmojis.length;
        if (!emoji || emoji === '') {
            emoji = defaultEmojis[Math.floor(Math.random() * len)].emoji;
        }

        let newGroup = {
            id: newId,
            title: title,
            createdBy: userId,
            emoji: emoji,
            categoryID: currentCategoryID,
            lastAdded: dateCreated,
            tabs: movedTabs,
            ...currentSlug
        };
        tabGroups.push(newGroup);
        useSelectStore.getState().clearSelection();
        //layoutStore.getState().setNewlyAddedGroup([newId]); only partly focuses the new group
        resp = await useStore
            .getState()
            .updateTabGroups(
                tabGroups,
                { type: 'groups', ids: [newId] },
                true,
                true,
                500
            );

        if (resp.msg === 'success') {
            showToast('Group created', 'Give it a title', 'success');
        } else {
            useStore
                .getState()
                .updateTabGroups(
                    fallbackState,
                    { type: 'groups', ids: [newId] },
                    false
                );

            console.error('Error creating new group', resp);
            showToast('Error creating new group', `Please try again`, 'error');
        }
    } else if (groupsInCategorie >= 16) {
        showToast(
            'Max groups reached',
            'Create a new category to create more groups',
            'error'
        );
    }
    return resp;
};
