import { v1 as uuidv1, v4 as uuidv4 } from 'uuid';
import defaultEmojis from '../../Utils/defaultEmojis';
import usePersistStore from '../persistStore';
import { useRemindersStore } from '../remindersStore';
import { showToast } from '../../../sharedCode/Utils/showToastWrapper';
import {
    findGroup,
    findTab
} from '../../../sharedCode/Utils/findDataFunctions';
import useStore from '../store';
import { dir, group } from 'console';
import layoutStore from '../layoutStore';
import useSelectStore from '../selectStore';
import { isTrialReached } from '../../../sharedCode/components/userApi';
import {
    convertActiveTabToPersistedTab,
    filterDraggedItems
} from '../../../platformFiles/extensionSpecific/handleActiveTabMove';
import { closeAllTabs, closeTabs } from '../../../platformFiles/browserAPI';
import { getItemType } from '../../../sharedCode/Utils/getItemType';
import { layout } from '@chakra-ui/react';
import {
    deleteDeletedItemsDataById,
    deleteWorkspace,
    getWsData,
    moveCategory,
    moveGroupData,
    moveTabBetweenGroups,
    updateCategories,
    updateWsMeta
} from '../../../platformFiles/platformMessages';
import {
    checkFavIconLength,
    favIconFetchGoogle
} from '../../../sharedCode/Utils/favIconUtils';

export const deleteGroupAction = (get, id) => {
    let tabGroups = get().tabGroups;
    let currentWsId = get().currentWsId;
    let previousTabs = structuredClone(get().tabGroups);

    let groupIndex = tabGroups.findIndex((target) => target.id === id);
    let groupToRe = tabGroups[groupIndex];
    let newSpace = tabGroups.filter((item) => item.id !== id);
    //find out if any of tabs.tabs has alarm(hasAlarm) if so check if userId(userData.userId) is in it, if so, clear it from alarm
    let hasAlarm = groupToRe.tabs.some((tab) => tab.hasAlarm);
    if (hasAlarm) {
        let tabsWithAlarm = groupToRe.tabs.filter((tab) => tab.hasAlarm);
        tabsWithAlarm.forEach((tab) => {
            let clearReminder = useRemindersStore.getState().clearReminder;
            clearReminder(tab.id, currentWsId);
        });
    }
    get().saveDeletedData([groupToRe], 'group');
    get().updateTabGroups(newSpace, null);
    const resClick = () => {
        restoreTabsAfterDeleted(previousTabs, [id]);
    };
    get().setShowToast('Group deleted', '', 'restore', resClick);
};

export const deleteCategoryAction = async (get, index) => {
    let categoriesClone = structuredClone(get().categories);
    if (categoriesClone.length < 2) {
        showToast(
            'At least one category per workspace required',
            'Delete not permitted',
            'error'
        );
        return;
    }
    let categoryId = categoriesClone[index].id;
    let categoryName = categoriesClone[index].name;

    categoriesClone.splice(index, 1);
    let tabGroups = structuredClone(get().tabGroups);
    let removedGroups = tabGroups.filter(
        (group) => group.categoryID === categoryId
    );
    let currentWsId = get().currentWsId;

    if (removedGroups.length) {
        removedGroups.forEach((group) => {
            let hasAlarm = group.tabs.some((tab) => tab.hasAlarm);
            if (hasAlarm) {
                let tabsWithAlarm = group.tabs.filter((tab) => tab.hasAlarm);
                tabsWithAlarm.forEach((tab) => {
                    let clearReminder =
                        useRemindersStore.getState().clearReminder;
                    clearReminder(tab.id, currentWsId);
                });
            }
        });
    }
    get().saveDeletedData(removedGroups, 'group');
    let newTabData = tabGroups.filter(
        (group) => group.categoryID !== categoryId
    );
    get()
        .updateCategories(categoriesClone, newTabData)
        .then((resp) => {
            if (resp.msg === 'success') {
                showToast(`${categoryName} was deleted`, ``, 'success');
            } else {
                console.error('Error deleting category', resp);
                showToast(
                    'Error deleting category',
                    `Please refresh page and try again`,
                    'error'
                );
            }
            // Handle the response here
        });
    let selectedCategory = usePersistStore.getState().selectedCategory;
    if (selectedCategory > index) {
        usePersistStore.getState().setSelectedCategory(selectedCategory - 1);
        useStore.getState().setGroupsToShow(selectedCategory - 1);
    } else if (selectedCategory === index) {
        usePersistStore.getState().setSelectedCategory(0);
        useStore.getState().setGroupsToShow(0);
    }
};

// Main function to update tab data
export const updateTabAction = async (
    get,
    newTabData,
    tabId,
    groupId,
    updateType = {
        type: 'items',
        ids: [tabId]
    },
    debounceDelay = null
) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);
    if (groupToUpdate) {
        let tabToUpdate = findTab(groupToUpdate, tabId);
        if (tabToUpdate) {
            // Update the tab with new data
            let currentTimestamp = Date.now();
            if (tabToUpdate.hasAlarm) {
                useRemindersStore.getState().updateReminder(tabToUpdate.id, {
                    ...newTabData,
                    lastEdited: currentTimestamp
                });
            }
            Object.assign(tabToUpdate, {
                ...newTabData,
                lastEdited: currentTimestamp
            });
            // Optionally, update the state if needed
            // set({ tabGroups: [...tabGroups] });
            await get().updateTabGroups(
                tabGroups,
                updateType,
                true,
                true,
                debounceDelay
            );
        }
    }
    return;
};

export const updateGroupAction = async (get, newGroupData, groupId) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);

    let isDataChanged = Object.keys(newGroupData).some((key) => {
        return newGroupData[key] !== groupToUpdate[key];
    });
    // Only update if there's at least one change
    if (isDataChanged) {
        Object.assign(groupToUpdate, newGroupData);
        await get().updateTabGroups(tabGroups, {
            type: 'groups',
            ids: [groupId]
        });
    }
    return;
    return;
};

export const toggleMinimizeGroupAction = (get, groupId, collapsedOrNot) => {
    console.log('tabGroups', groupId, collapsedOrNot);
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);
    console.log('groupToUpdate', groupToUpdate);
    if (groupToUpdate) {
        groupToUpdate.collapsed = collapsedOrNot;
        get().updateTabGroups(tabGroups);
    }
};

export const addGroupAction = (get) => {
    let category = usePersistStore.getState().selectedCategory;
    let tabGroups = get().tabGroups;
    let userId = get().userData.userId;
    const currentCategoryID = get().categories[category].id;
    const currentSlug = get().categories[category].slug && {
        slug: get().categories[category].slug
    };
    const groupsInCategorie = tabGroups.filter(
        (tabs) => tabs.categoryID === currentCategoryID
    ).length;
    if (groupsInCategorie < 16) {
        const dateCreated = new Date().toString();
        const newId = uuidv1();
        const len = defaultEmojis.length;
        const randomEmoji =
            defaultEmojis[Math.floor(Math.random() * len)].emoji;
        let oldTabs = [...tabGroups];
        let newTabs = [
            ...oldTabs,
            {
                id: newId,
                title: 'Untitled',
                createdBy: userId,
                emoji: randomEmoji,
                categoryID: currentCategoryID,
                lastAdded: dateCreated,
                tabs: [],
                ...currentSlug
            }
        ];
        useSelectStore.getState().clearSelection();
        layoutStore.getState().setNewlyAddedGroup([newId]);
        showToast('Group created', 'Give it a title', 'success');
        get().updateTabGroups(newTabs);
    } else if (groupsInCategorie >= 16) {
        showToast(
            'Max groups reached',
            'Create a new category to create more groups',
            'error'
        );
    }
};

export const createNoteAction = (get, set, groupId) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);
    if (groupToUpdate) {
        let dateCreated = new Date().toString();
        let newId = uuidv1();
        let oldTabs = [...groupToUpdate.tabs];
        let newTabs = [
            ...oldTabs,
            {
                id: newId,
                title: 'Untitled',
                type: 'note',
                createdBy: get().userData.userId,
                lastAdded: dateCreated,
                justAdded: true
            }
        ];
        groupToUpdate.tabs = newTabs;
        set({ tabGroups: tabGroups });
        //get().updateTabGroups(tabGroups, null, false);
    }
};

export const deleteNoteAction = (get, groupId, noteId, justAdded) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);
    if (groupToUpdate) {
        let oldTabs = [...groupToUpdate.tabs];
        let newTabs = oldTabs.filter((item) => item.id !== noteId);
        groupToUpdate.tabs = newTabs;
        if (justAdded) {
            get().updateTabGroups(tabGroups, null, false);
        } else {
            get().updateTabGroups(tabGroups);
        }
    }
};

export const deleteSelectedItemsAction = () => {};
export const deleteItemsAction = (get, idsToDelete) => {
    let tabGroups = structuredClone(get().tabGroups);
    let currentWsId = get().currentWsId;
    if (typeof idsToDelete === 'string') {
        idsToDelete = [idsToDelete];
    }
    let countLenght = idsToDelete.length;
    useSelectStore.getState().clearSelection();
    let deletedItems = [];
    function removeItemsById(groupData, idsToDelete) {
        groupData.forEach((group) => {
            // Process the tabsArray
            group.tabs = group.tabs.reduce((acc, tabOrNote) => {
                // Check if it's a stacked item
                if (tabOrNote.isStacked) {
                    if (idsToDelete.includes(tabOrNote.id)) {
                        if (tabOrNote.hasAlarm) {
                            let clearReminder =
                                useRemindersStore.getState().clearReminder;
                            clearReminder(tabOrNote.id, currentWsId);
                        }
                        deletedItems.push(tabOrNote);
                    } else {
                        let removedFromStack = false;
                        tabOrNote.stackedItems = tabOrNote.stackedItems.filter(
                            (stackedItem) => {
                                if (idsToDelete.includes(stackedItem.id)) {
                                    if (tabOrNote.hasAlarm) {
                                        let clearReminder =
                                            useRemindersStore.getState()
                                                .clearReminder;
                                        clearReminder(
                                            stackedItem.id,
                                            currentWsId
                                        );
                                    }
                                    deletedItems.push(stackedItem);
                                    removedFromStack = true;
                                    return false;
                                }
                                return true;
                            }
                        );

                        // Check the length of stackedItems after filtering
                        if (
                            tabOrNote.stackedItems.length === 1 &&
                            removedFromStack
                        ) {
                            // Unstack the last item and move it to acc (main tabs array)
                            let lastItem = tabOrNote.stackedItems[0];
                            acc.push(lastItem);
                        } else if (tabOrNote.stackedItems.length > 0) {
                            // If there are multiple stacked items, keep the tab
                            acc.push(tabOrNote);
                        } else if (idsToDelete.includes(tabOrNote.id)) {
                            // If the entire tab (with the stack) is to be deleted
                            if (tabOrNote.hasAlarm) {
                                let clearReminder =
                                    useRemindersStore.getState().clearReminder;
                                clearReminder(tabOrNote.id, currentWsId);
                            }
                            deletedItems.push(tabOrNote);
                        }
                    }
                } else {
                    // Check for normal tabs or notes
                    if (!idsToDelete.includes(tabOrNote.id)) {
                        acc.push(tabOrNote);
                    } else {
                        if (tabOrNote.hasAlarm) {
                            let clearReminder =
                                useRemindersStore.getState().clearReminder;
                            clearReminder(tabOrNote.id, currentWsId);
                        }
                        deletedItems.push(tabOrNote);
                    }
                }
                return acc;
            }, []);
        });

        return deletedItems;
    }
    removeItemsById(tabGroups, idsToDelete);
    get().saveDeletedData(deletedItems, 'item');

    let previousTabs = structuredClone(get().tabGroups);
    const resClick = () => {
        restoreTabsAfterDeleted(previousTabs, idsToDelete);
    };
    get().updateTabGroups(tabGroups, null);
    let isOneItem = countLenght === 1;
    let isStacked = isOneItem && deletedItems[0]?.isStacked ? true : false;
    let howManyStacked = deletedItems[0]?.stackedItems?.length;
    let toastMessage = isStacked
        ? howManyStacked + ' stacked items deleted'
        : countLenght +
          (countLenght === 1 ? ' item deleted' : ' items deleted');
    // get().setPrevTabs(resArray);
    showToast(toastMessage, '', 'restore', resClick);
    // get().setShowToast(toastMessage, previousTabs, 'success');
};

export const submitNoteAction = (
    get,
    set,
    groupId,
    noteId,
    noteData,
    createAnother = false
) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);
    if (groupToUpdate) {
        let tabToUpdate = findTab(groupToUpdate, noteId);

        if (tabToUpdate) {
            let currentTimestamp = Date.now();
            if (tabToUpdate.hasAlarm) {
                useRemindersStore.getState().updateReminder(tabToUpdate.id, {
                    ...noteData,
                    lastEdited: currentTimestamp
                });
            }
            Object.assign(tabToUpdate, {
                ...noteData,
                lastEdited: currentTimestamp
            });
            get().updateTabGroups(tabGroups, {
                type: 'groups',
                ids: [groupId]
            });
            if (createAnother) {
                if (isTrialReached()) return;
                let isTodo = noteData.todo ? true : false;
                let groupToUpdate = findGroup(tabGroups, groupId);
                if (groupToUpdate) {
                    let dateCreated = new Date().toString();
                    let newId = uuidv1();
                    let oldTabs = [...groupToUpdate.tabs];

                    let prevNoteIndex = tabToUpdate.id
                        ? oldTabs.findIndex(
                              (target) => target.id === tabToUpdate.id
                          ) + 1
                        : oldTabs.length;

                    let newTabs = [
                        ...oldTabs.slice(0, prevNoteIndex),
                        {
                            id: newId,
                            title: 'Untitled',
                            type: 'note',
                            createdBy: get().userData.userId,
                            lastAdded: dateCreated,
                            justAdded: true,
                            todo: isTodo
                        },
                        ...oldTabs.slice(prevNoteIndex)
                    ];
                    layoutStore.getState().setNoteEditMode(newId);
                    groupToUpdate.tabs = newTabs;
                    set({ tabGroups: tabGroups });
                    //layoutStore.getState().setBlockScaleUp(false);
                    //get().updateTabGroups(tabGroups, null, false);
                }
            }
        }
    }
};

export const updateWsMetaAction = (get, newData, wsId) => {
    let userData = structuredClone(get().userData);
    let wsData = userData.workspaces.find((workspace) => workspace.id === wsId);
    Object.assign(wsData, newData);
    if (!wsData.shared) {
        return get().updateUserData({ workspaces: userData.workspaces });
    } else {
        get().updateUserData({ workspaces: userData.workspaces }, false);
        updateWsMeta(wsId, newData);
    }
};

export const changeWsIndexAction = (get, wsId, direction) => {
    let userData = structuredClone(get().userData);
    let index = userData.workspaces.findIndex((target) => target.id === wsId);
    let wsData = userData.workspaces.find((workspace) => workspace.id === wsId);
    let newIndex = direction === 'up' ? index - 1 : index + 1;
    if (newIndex < 0 || newIndex >= userData.workspaces.length) {
        return;
    }
    let temp = userData.workspaces[newIndex];
    userData.workspaces[newIndex] = wsData;
    userData.workspaces[index] = temp;
    return get().updateUserData({ workspaces: userData.workspaces });
};

const restoreTabsAfterDeleted = (tabsToRe, tabIds) => {
    useStore.getState().updateTabGroups(tabsToRe);
    deleteDeletedItemsDataById(tabIds);
};

export const updateCategoriesAction = async (
    get,
    set,
    newCategories,
    newTabData = false
) => {
    let userData = structuredClone(get().userData);
    let workspaces = userData.workspaces;
    let currentWsId = get().currentWsId;
    let index = workspaces.findIndex((target) => target.id === currentWsId);
    workspaces[index].catLength = newCategories.length;
    workspaces[index].catData = newCategories;
    let userUpdateId = uuidv4();
    let wsUpdateId = uuidv4();
    userData.updateId = userUpdateId;
    set({
        userData: userData,
        categories: newCategories,
        wsUpdateId: wsUpdateId,
        ...(newTabData && { tabGroups: newTabData })
    });

    return new Promise((resolve, reject) => {
        updateCategories(
            newCategories,
            {
                categories: newCategories,
                updateId: wsUpdateId,
                ...(newTabData && { tabData: newTabData })
            },
            currentWsId
        ).then((response) => {
            if (response?.msg === 'success') {
                resolve(response);
            } else {
                reject(new Error('Failed to update categories'));
            }
        });
    });
};

export const duplicateGroupAction = async (get, groupId) => {
    let tabGroups = structuredClone(get().tabGroups);
    if (get().groupsToShow.length > 15) {
        showToast(
            'Max groups reached',
            'Create a new category to create more groups',
            'error'
        );
        return;
    }
    let index = tabGroups.findIndex((group) => group.id === groupId);
    let duplicatedSpace = structuredClone(tabGroups[index]);
    duplicatedSpace.tabs.forEach((item) => {
        item.id = uuidv1();
        if (item.hasOwnProperty('hasAlarm')) {
            delete item.hasAlarm;
        }
        if (item.isStacked) {
            item.stackedItems.forEach((item) => {
                item.id = uuidv1();
            });
        }
    });
    let newIdGroupId = uuidv1();
    duplicatedSpace.id = newIdGroupId;
    let lastStringPos = duplicatedSpace.title.charAt(
        duplicatedSpace.title.length - 1
    );
    let nextlastStringPos = duplicatedSpace.title.charAt(
        duplicatedSpace.title.length - 2
    );
    let nextnextLastStringPos = duplicatedSpace.title.charAt(
        duplicatedSpace.title.length - 3
    );
    let newTitle;
    if (
        lastStringPos === ')' &&
        nextlastStringPos >= '0' &&
        nextlastStringPos <= '9' &&
        nextnextLastStringPos === '('
    ) {
        newTitle =
            duplicatedSpace.title.slice(0, -3) +
            '(' +
            (parseInt(nextlastStringPos) + 1) +
            ')';
    } else {
        newTitle = duplicatedSpace.title + '(1)';
    }
    duplicatedSpace.title = newTitle;
    if (index === tabGroups.length - 1) {
        tabGroups.push(duplicatedSpace);
    } else {
        tabGroups.splice(index + 1, 0, duplicatedSpace);
    }
    let resp = await get().updateTabGroups(tabGroups, {
        type: 'groups',
        ids: [newIdGroupId]
    });
    if (resp.msg === 'success') {
        showToast(
            'Group duplicated',
            `${tabGroups[index].title} duplicated successfully`,
            'success'
        );
    }
};

export const copyContentAction = (get, groupId) => {
    let tabGroups = get().tabGroups;
    var index = tabGroups.findIndex((target) => target.id === groupId);
    if (tabGroups[index].tabs.length === 0) {
        get().setShowToast(
            'No items to copy',
            'There seems to be no items in the group',
            'info'
        );
        return;
    }
    let textToCopy = tabGroups[index].tabs.reduce(function (str, space) {
        return (
            str +
            (space.url?.length > 0 ? space.url + '\n' : '') +
            (space.comment.length > 0 ? space.comment + '\n' : '')
        );
    }, '');
    navigator.clipboard.writeText(textToCopy);
    get().setShowToast(
        'Text copied',
        'All text inside group copied to clipboard',
        'success'
    );
};

export const sortGroupAlphabeticallyAction = (get, groupId) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);
    if (groupToUpdate.tabs.length === 0) {
        get().setShowToast(
            'No items to order',
            'There seems to be no items in the group',
            'info'
        );
    }
    if (groupToUpdate) {
        console.log('groupToUpdate', groupToUpdate);
        groupToUpdate.tabs.sort((a, b) => {
            const commentA = a.comment || '';
            const commentB = b.comment || '';
            const titleA = a.title || '';
            const titleB = b.title || '';

            return (
                commentA.localeCompare(commentB) || titleA.localeCompare(titleB)
            );
        });
        get().updateTabGroups(tabGroups);
        get().setShowToast(
            'Items sorted',
            'The groups content has been sorted alphabetically',
            'success'
        );
    }
};

export const sortGroupByDateAction = (get, groupId) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupId);
    if (groupToUpdate.tabs.length === 0) {
        get().setShowToast(
            'No items to order',
            'There seems to be no items in the group',
            'info'
        );
    }
    if (groupToUpdate) {
        groupToUpdate.tabs.sort((a, b) => {
            const dateA = a.dateCreated || a.lastAdded || '';
            const dateB = b.dateCreated || b.lastAdded || '';
            return dateA.localeCompare(dateB);
        });
        get().updateTabGroups(tabGroups);
        get().setShowToast(
            'Items sorted',
            'The groups content has been sorted by date',
            'success'
        );
    }
};

export const deleteWorkspaceAction = async (get, set, wsId, isOwner) => {
    let workspacesDeleting = get().workspacesUnderDeletion;
    let workspacesDeletingFallback = structuredClone(
        get().workspacesUnderDeletion
    );
    if (!workspacesDeleting.includes(wsId)) {
        workspacesDeleting.push(wsId);
        set({ workspacesUnderDeletion: [...workspacesDeleting] });
    }

    let userData = get().userData;
    let userId = userData.userId;
    let currentWsId = get().currentWsId;
    let changeToWs = null;
    let workspaces = userData.workspaces;
    let numberOfOwnerWs = 0;
    let currentName = 'untiteled';
    workspaces.forEach((ws) => {
        if (ws.id === wsId) {
            currentName = ws.name;
        }
        if (!ws.shared || (ws.shared && ws.owner === userId)) {
            numberOfOwnerWs++;
        }
    });
    if (numberOfOwnerWs < 2) {
        showToast(
            'Cannot remove workspace',
            'You need to have at least one workspace where you are owner, create a new workspace first to remove the current',
            'error'
        );
        set({ workspacesUnderDeletion: [workspacesDeletingFallback] });
        return;
    }

    if (currentWsId === wsId) {
        workspaces.forEach((ws) => {
            if (!changeToWs && ws.id !== wsId) {
                changeToWs = ws.id;
            }
        });
        usePersistStore.getState().setSelectedCategory(0);
        set({
            tabGroups: null,
            wsUpdateId: 'initStore',
            currentWsId: changeToWs,
            categories: null,
            changeWsLoading: true
        });
    }

    await deleteWorkspace({
        wsId: wsId,
        userToRemove: { id: userId, email: userData.email },
        changeToWs: changeToWs,
        isOwner: isOwner
    }).then((resp) => {
        let workspacesDeleting = get().workspacesUnderDeletion;
        let updatedWorkspacesDeleting = workspacesDeleting.filter(
            (workspaceId) => workspaceId !== wsId
        );

        //@ts-ignore
        if (resp.data?.success) {
            showToast(
                'Workspace removed',
                `${currentName} removed successfully`,
                'success'
            );
        } else {
            set({ workspacesUnderDeletion: updatedWorkspacesDeleting });

            showToast(
                'Something went wrong',
                `Workspace removal failed, please try again`,
                'error'
            );
        }
    });
    return;
};

export const moveGroupDataAction = (
    get,
    set,
    groupToMove,
    toWsId,
    toCatId,
    toCatName
) => {
    let currentWsId = get().currentWsId;
    let tabGroups = structuredClone(get().tabGroups);
    let originalTabGroups = structuredClone(get().tabGroups);
    let group = findGroup(tabGroups, groupToMove);
    let title = group?.title ? group.title : '';

    moveGroupData({
        groupToMoveId: groupToMove,
        toWsId: toWsId,
        toCatId: toCatId,
        currentTabData: tabGroups,
        currentWsId: currentWsId
    }).then((resp) => {
        if (resp?.msg === 'success') {
            showToast(
                `Group moved successfully`,
                `${title} moved into ${toCatName}`,
                'success'
            );
        } else {
            set({ tabGroups: originalTabGroups });
            showToast(
                'Something went wrong',
                `Group move failed, please try again`,
                'error'
            );
            console.error('group move error', resp);
        }
    });

    if (currentWsId === toWsId) {
        let group = findGroup(tabGroups, groupToMove);
        group.categoryID = toCatId;
        set({ tabGroups: tabGroups });
    } else {
        tabGroups = tabGroups.filter((group) => group.id !== groupToMove);
        set({ tabGroups: tabGroups });
    }
};

export const moveTabToGroupAction = (
    get,
    set,
    tabsToMove,
    toWsId,
    toGroupId
) => {
    let currentWsId = get().currentWsId;
    let tabGroups = structuredClone(get().tabGroups);
    let originalTabGroups = structuredClone(get().tabGroups);
    moveTabBetweenGroups({
        tabIds: tabsToMove,
        toWsId: toWsId,
        toGroupId: toGroupId,
        currentTabData: tabGroups,
        currentWsId: currentWsId
    }).then((resp) => {
        if (resp?.msg === 'success') {
            let toastText =
                tabsToMove.length > 1
                    ? 'Tabs moved successfully'
                    : 'Tab moved successfully';
            showToast(toastText, ``, 'success');
        } else {
            set({ tabGroups: originalTabGroups });
            showToast(
                'Something went wrong',
                `Tab move failed, please try again`,
                'error'
            );
            console.error('group move error', resp);
        }
    });

    if (currentWsId === toWsId) {
        let tabItemsToMove = [];
        const updatedCurrentTabData = tabGroups.map((group) => {
            return {
                ...group,
                tabs: group.tabs.filter((tab) => {
                    if (tabsToMove.includes(tab.id)) {
                        tabItemsToMove.push(tab);
                        return false;
                    }
                    return true;
                })
            };
        });
        let group = findGroup(updatedCurrentTabData, toGroupId);
        group.tabs.push(...tabItemsToMove);
        set({ tabGroups: updatedCurrentTabData });
    } else {
        const updatedCurrentTabData = tabGroups.map((group) => {
            return {
                ...group,
                tabs: group.tabs.filter((tab) => {
                    if (tabsToMove.includes(tab.id)) {
                        // tabItemsToMove.push(tab);
                        return false;
                    }
                    return true;
                })
            };
        });
        set({ tabGroups: updatedCurrentTabData });
    }
};

export const hydrateCachedGroupsAction = (get, set, wsId) => {
    let cachedGroups = structuredClone(get().cachedGroups);
    if (cachedGroups.hasOwnProperty(wsId)) {
        return;
    }
    cachedGroups[wsId] = 'loading';
    set({ cachedGroups: cachedGroups });
    getWsData(wsId).then((resp) => {
        if (resp?.msg === 'success') {
            let cachedGroups = structuredClone(get().cachedGroups);
            // let groups = []
            // resp.data.tabGroups?.forEach((group)=> {

            // })
            cachedGroups[wsId] = resp.data;
            set({ cachedGroups: cachedGroups });
        } else {
            showToast(
                'Something went wrong',
                `Could not fetch workspace data`,
                'error'
            );
            console.error('could not fetch', resp, wsId);
        }
    });
};

export const changeSpaceWidthAction = async (get, groupId, width) => {
    try {
        let tabGroups = structuredClone(get().tabGroups);
        let group = findGroup(tabGroups, groupId);
        group.customWidth = width;
        let resp = await get().updateTabGroups(tabGroups, {
            type: 'groups',
            ids: [groupId]
        });
        if (resp.msg === 'success') {
            //showToast('Group width updated', ``, 'success');
        } else {
            console.error('error updating group width', resp);
            showToast(
                'Error updating group width',
                `Please try again`,
                'error'
            );
        }
    } catch (e) {
        console.error('error updating group width', e);
        showToast('Error updating group width', `Please try again`, 'error');
    }
};

export const toggleMetaImagePinAction = async (
    get,
    groupId,
    tabId,
    imageUrl
) => {
    let tabGroups = structuredClone(get().tabGroups);
    let group = findGroup(tabGroups, groupId);
    let tab = findTab(group, tabId);
    let currentStatus = tab.metaUrl ? true : false;
    let newStatus = currentStatus ? false : imageUrl;
    let message = newStatus ? 'Meta image pinned' : 'Meta image unpinned';
    tab.metaUrl = newStatus;
    let resp = await get().updateTabGroups(tabGroups, {
        type: 'tabs',
        ids: [tabId]
    });

    if (resp.msg === 'success') {
        showToast(message, ``, 'success');
    } else {
        console.error('error pinning meta image', resp);
        showToast('Error updating meta image pin', `Please try again`, 'error');
    }
    return resp;
};

const saveAllActiveTabsIntoGroup = async (get, groupId, activeWindowId) => {
    let tabGroups = structuredClone(useStore.getState().tabGroups);
    let activeTabs = structuredClone(
        useStore.getState().allActiveTabs[activeWindowId].activeTabs
    );
    let originalActiveTabs = structuredClone(
        useStore.getState().allActiveTabs[activeWindowId].activeTabs
    );
    let tabsToAdd = [];
    activeTabs.forEach((activeTab) => {
        if (activeTab.children) {
            let newId = uuidv4();
            const dateCreated = new Date().toString();
            let newStack = {
                id: newId,
                isStacked: true,
                stackedItems: [],
                dateCreated: dateCreated,
                comment: '',
                commentColor: false,
                title: activeTab.title ? activeTab.title : '',
                emoji: activeTab.emoji ? activeTab.emoji : null
            };
            activeTab.children.forEach((childTab) => {
                let persistChildTab = convertActiveTabToPersistedTab(childTab);
                newStack.stackedItems.push(persistChildTab);
            });
            tabsToAdd.push(newStack);
        } else {
            let persistTab = convertActiveTabToPersistedTab(activeTab);
            tabsToAdd.push(persistTab);
        }
    });
    let overGroup = findGroup(tabGroups, groupId);
    overGroup.tabs.push(...tabsToAdd);
    useStore.getState().setActiveTabsInWindow([], activeWindowId);
    let resp = await get().updateTabGroups(tabGroups, {
        type: 'groups',
        ids: [groupId]
    });
    if (resp.msg === 'success') {
        showToast(
            'Save done',
            `All active tabs saved and closed into ${overGroup.title}`,
            'success'
        );
        closeAllTabs(activeWindowId);
    } else {
        console.error('error saving tab', resp);
        showToast('Error saving tab', `Please try again`, 'error');
        useStore
            .getState()
            .setActiveTabsInWindow(originalActiveTabs, activeWindowId);
    }
};

export const saveActiveTabAction = async (get, activeTabId, groupId) => {
    if (isTrialReached()) {
        return;
    }
    let activeWindowId = get().activeWindowId;

    if (activeTabId === 'allActiveTabs') {
        saveAllActiveTabsIntoGroup(get, groupId, activeWindowId);
        return;
    }

    let tabGroups = structuredClone(get().tabGroups);

    let activeTabs = structuredClone(
        useStore.getState().allActiveTabs[activeWindowId].activeTabs
    );
    let originalActiveTabs = structuredClone(
        useStore.getState().allActiveTabs[activeWindowId].activeTabs
    );
    const { filteredActiveTabs, movedItems } = filterDraggedItems(
        activeTabId,
        activeTabs
    );

    let tabIdsToClose = [activeTabId];
    let persistTab;
    let successMsg;
    if (movedItems[0].children) {
        tabIdsToClose = [];
        let tabsToAdd = [];
        movedItems[0].children.forEach((activeTab) => {
            tabIdsToClose.push(activeTab.id);
            let persistTab = convertActiveTabToPersistedTab(activeTab);
            tabsToAdd.push(persistTab);
        });
        let newId = uuidv4();
        const dateCreated = new Date().toString();
        console.log('movedItems', movedItems);
        persistTab = {
            id: newId,
            isStacked: true,
            stackedItems: tabsToAdd,
            dateCreated: dateCreated,
            comment: '',
            commentColor: false,
            title: movedItems[0].title,
            emoji: movedItems[0].hasEmoji ? movedItems[0].emoji : undefined
        };
        successMsg = 'Group saved into ';
    } else {
        persistTab = convertActiveTabToPersistedTab(movedItems[0]);
        successMsg = 'Tab saved into ';
    }
    let overGroup = findGroup(tabGroups, groupId);
    overGroup.tabs.push(persistTab);
    useStore
        .getState()
        .setActiveTabsInWindow(filteredActiveTabs, activeWindowId);
    let resp = await get().updateTabGroups(tabGroups, {
        type: 'groups',
        ids: [groupId]
    });
    successMsg += overGroup.title;

    if (resp.msg === 'success') {
        showToast('Save done', successMsg, 'success');
        closeTabs(tabIdsToClose, activeWindowId, false, true);
    } else {
        console.error('error saving tab', resp);
        showToast('Error saving tab', `Please try again`, 'error');
        useStore
            .getState()
            .setActiveTabsInWindow(originalActiveTabs, activeWindowId);
    }
};

export const moveCategoryAction = (
    originalWsId,
    newWsId,
    catIdToMove,
    newIndex,
    fallbackState,
    setFallbakState,
    setDragLoading
) => {
    let newWsState = structuredClone(fallbackState);
    let originalWsIndex = newWsState.findIndex((ws) => ws.id === originalWsId);
    const movedCategory = newWsState[originalWsIndex].catData.find(
        (cat) => cat.id === catIdToMove
    );
    newWsState[originalWsIndex].catData = newWsState[
        originalWsIndex
    ].catData.filter((cat) => cat.id !== catIdToMove);

    let movedToIndex = newWsState.findIndex((ws) => ws.id === newWsId);
    if (newIndex >= newWsState[movedToIndex].catData.length) {
        newWsState[movedToIndex].catData.push(movedCategory);
    } else {
        newWsState[movedToIndex].catData.splice(newIndex, 0, movedCategory);
    }
    useStore.getState().updateUserData({ workspaces: newWsState }, false);
    moveCategory({
        originalWsId: originalWsId,
        newWsId: newWsId,
        catIdToMove: catIdToMove,
        newIndex: newIndex
    }).then((resp) => {
        if (resp?.msg === 'success') {
            showToast('Category moved successfully', ``, 'success');
            setFallbakState(null);
            setDragLoading(null);
        } else {
            showToast('Something went wrong', `Please try again`, 'error');
            console.error('could not fetch', resp);
            if (fallbackState) {
                useStore
                    .getState()
                    .updateUserData({ workspaces: fallbackState }, false);
            }
            setFallbakState(null);
            setDragLoading(null);
        }
    });
};

export const stackSelectedItemsAction = (get) => {
    let tabGroups = structuredClone(get().tabGroups);
    let selectedGroup = useSelectStore.getState().selectedContainer;
    let selectedIds = useSelectStore.getState().selectedIds;
    let anySelectedStack =
        useSelectStore.getState().selectedExtraInfo.anySelectedStack;

    let groupData = findGroup(tabGroups, selectedGroup);

    if (anySelectedStack) {
        let stacksToUnstack = [];
        groupData.tabs.forEach((tab, index) => {
            if (selectedIds.includes(tab.id) && tab.isStacked) {
                stacksToUnstack.unshift({
                    stackedItems: tab.stackedItems,
                    index: index
                });
            }
        });
        stacksToUnstack.forEach((stack) => {
            groupData.tabs.splice(stack.index, 1, ...stack.stackedItems);
        });
    } else {
        let firstSiteIndex = null;
        let firstNoteIndex = null;
        let noteItems = [];
        let siteItems = [];
        groupData.tabs.forEach((tab, index) => {
            if (selectedIds.includes(tab.id)) {
                let itemType = getItemType(tab);
                if (itemType === 'tabItem') {
                    if (!firstSiteIndex && firstSiteIndex !== 0) {
                        firstSiteIndex = index;
                    }
                    siteItems.push(tab);
                } else if (itemType === 'noteItem') {
                    if (!firstNoteIndex && firstNoteIndex !== 0) {
                        firstNoteIndex = index;
                    }
                    noteItems.push(tab);
                }
            }
        });
        if (siteItems.length > 1) {
            let newId = uuidv4();
            const dateCreated = new Date().toString();

            let newStack = {
                id: newId,
                isStacked: true,
                stackedItems: [],
                dateCreated: dateCreated,
                comment: '',
                commentColor: false,
                title: ''
            };
            let siteIds = [];

            siteItems.forEach((site, index) => {
                siteIds.push(site.id);
                newStack.stackedItems.push(site);
            });
            groupData.tabs = groupData.tabs.filter(
                (tab) => !siteIds.includes(tab.id)
            );
            groupData.tabs.splice(firstSiteIndex, 0, newStack);
        }
        if (noteItems.length > 1) {
            if (siteItems.length > 1) {
                firstNoteIndex = groupData.tabs.findIndex(
                    (tab) => tab.id === noteItems[0].id
                );
            }
            let combinedNoteItem = noteItems[0];
            let noteIds = [];
            noteItems.forEach((note, index) => {
                noteIds.push(note.id);
                if (index === 0) {
                    return;
                }
                combinedNoteItem.comment =
                    combinedNoteItem.comment + '\n' + note.comment;
            });

            groupData.tabs = groupData.tabs.filter(
                (tab) => !noteIds.includes(tab.id)
            );
            groupData.tabs.splice(firstNoteIndex, 0, combinedNoteItem);
        }
    }

    get()
        .updateTabGroups(tabGroups, null)
        .then((resp) => {
            if (resp.msg === 'success') {
                if (anySelectedStack) {
                    showToast(
                        'Stack unstacked',
                        'Sites split into individual items',
                        'success'
                    );
                } else {
                    showToast(
                        'Stack created',
                        'Sites merged into one stacked item',
                        'success'
                    );
                }
            }
            if (resp.msg !== 'success') {
                showToast(
                    'Something went wrong',
                    'Error updating database on stack action',
                    'error'
                );
            }
        });
};

export const turnNoteIntoSiteAction = (
    get,
    set,
    pastedData,
    prevValue,
    groupId,
    id
) => {
    let cleanUrl = pastedData.replace('https://', '');
    let urlFromInput = 'https://' + cleanUrl;
    let favIconUrl = favIconFetchGoogle(urlFromInput);
    let newId = uuidv1();
    let dateobj = new Date().toString();
    let dateCreated = dateobj;
    let newData = structuredClone(get().tabGroups);
    let spaceIndex = newData.findIndex((target) => target.id === groupId);
    //remove the url from the note
    let groupToUpdate = findGroup(newData, groupId);
    let tabToUpdate = findTab(groupToUpdate, id);
    //if empty delete the note
    let newTabs;
    if (prevValue === '') {
        newTabs = groupToUpdate.tabs.filter((tab) => tab.id !== id);
        newData[spaceIndex].tabs = newTabs;
    } else {
        tabToUpdate.comment = prevValue;
    }
    newTabs = [
        ...newData[spaceIndex].tabs,
        {
            id: newId,
            title: 'Untitled',
            favIcon: favIconUrl,
            url: urlFromInput,
            comment: '',
            commentColor: false,
            note: false,
            dateCreated: dateCreated
        }
    ];

    newData[spaceIndex].tabs = newTabs;
    get().updateTabGroups(newData, { type: 'items', ids: [newId] });
    showToast(
        'Note turned into site',
        'The note has been turned into a site',
        'success'
    );
};

export const syncGroupSitesAction = async (
    get,
    groupData,
    extraUrlsInGroup,
    removedUrls
) => {
    let tabGroups = structuredClone(get().tabGroups);
    let groupToUpdate = findGroup(tabGroups, groupData.id);
    //if removed urls filter them out
    if (removedUrls) {
        groupToUpdate.tabs = groupToUpdate.tabs.filter(
            (tab) =>
                !removedUrls.some((removedUrl) => removedUrl.url === tab.url)
        );
    }
    //if extra urls add them
    if (extraUrlsInGroup) {
        extraUrlsInGroup.forEach((tab) => {
            let newId = uuidv1();
            let dateobj = new Date().toString();
            let dateCreated = dateobj;
            groupToUpdate.tabs.push({
                id: newId,
                title: tab.title,
                favIcon: checkFavIconLength(tab.favIcon, tab.url),
                url: tab.url,
                comment: '',
                commentColor: false,
                note: false,
                dateCreated: dateCreated
            });
        });
    }
    //add groupData to tabGroups
    let groupIndex = tabGroups.findIndex((group) => group.id === groupData.id);
    if (groupIndex !== -1) {
        tabGroups[groupIndex] = groupToUpdate;
    }

    get()
        .updateTabGroups(tabGroups, null)
        .then((resp) => {
            if (resp.msg === 'success') {
                showToast(
                    'Group updated',
                    'Sites in group was synced with the open tab group',
                    'success'
                );
            }
            if (resp.msg !== 'error') {
                showToast(
                    'Something went wrong',
                    'Error syncing sites in group',
                    'error'
                );
            }
        });
};
