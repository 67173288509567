import { create } from 'zustand';
import useStore from './store';
import { showToast } from '../Utils/showToastWrapper';
import useSelectStore from './selectStore';
import { fetchWsRoles } from '../../platformFiles/platformMessages';

type dockTypes = 'arrows' | 'noteToolbar' | 'selectionBar';
interface TabModalData {
    id: string;
    url: string;
    title: string;
    favIcon: string;
    groupId: string;
    dateCreated?: string;
    tabId?: string;
    hasAlarm?: any[] | undefined;
    metaUrl?: string;
    comment?: string;
    commentColor?: boolean;
    doneStatus?: boolean;
    justAdded?: boolean;
    note?: boolean;
    todo?: boolean;
    type?: string;
    groupColor?: string;
    emoji?: string;
}

interface UpdateDockType {
    type: string;
    value: number | string | boolean;
}

type Store = {
    hoverSide: boolean;
    setHoverSide: (hoverSide: boolean) => void;
    workspaceZoom: string;
    setWorkspaceZoom: (workspaceZoom: string) => void;
    sidePanelIsOpen: boolean;
    setSidePanelIsOpen: (sidePanelIsOpen: boolean) => void;
    noteEditMode: string | null;
    setNoteEditMode: (noteEditMode: string) => void;
    blockScaleUp: boolean | null;
    setBlockScaleUp: (status: boolean) => void;
    showBookmarksModal: boolean;
    setBookmarksModal: (showBookmarksModal: boolean) => void;
    showCatModal: boolean;
    setCatModal: (showCatModal: boolean) => void;
    isZooming: boolean;
    setIsZooming: (newStatus: boolean) => void;
    sidebarIsPinned: boolean;
    setPinSidebar: (newStatis: boolean) => void;
    emojiPickerOpen: string | null;
    setEmojiPickerOpen: (emojiPickerOpen: string) => void;
    showLimitModal: boolean;
    setShowLimitModal: (showLimitModal: boolean) => void;
    showShareModal: boolean;
    setShowShareModal: (showShareModal: boolean, id?: string) => void;
    newlyAddedGroup: string[];
    setNewlyAddedGroup: (newlyAddedGroup: string[]) => void;
    shareModalData: any;
    showBinDrawer: boolean;
    setShowBinDrawer: (showBinModal: boolean) => void;
    showSearchModal: boolean;
    setShowSearchModal: (showSearchModal: boolean) => void;
    activeInfoPopIndex: number | null;
    setActiveInfoPopIndex: (index: number) => void;
    currentDock: dockTypes | null;
    showDock: (dockType: dockTypes) => void;
    siteModalOpen: string | null;
    siteModalData: TabModalData | null;
    setSiteModalOpen: (data: TabModalData) => void;
    updateInputValue: (value: string) => void;
    updateDockFromOutside: UpdateDockType | null;
    setUpdateDockFromOutside: (data: UpdateDockType) => void;
    keepSidebarOpen: boolean;
    zoomXY: { x: number; y: number } | null;
    setZoomXY: (x: number, y: number) => void;
    showFeedbackModal: boolean;
    setShowFeedbackModal: (showFeedbackModal: boolean) => void;
};

const initialState = {
    hoverSide: false,
    workspaceZoom: 'zoomed-in',
    sidePanelIsOpen: false,
    noteEditMode: null,
    blockScaleUp: null,
    showBookmarksModal: false,
    showCatModal: false,
    isZooming: false,
    sidebarIsPinned: false,
    emojiPickerOpen: null,
    showLimitModal: false,
    showShareModal: false,
    shareModalData: null,
    newlyAddedGroup: [],
    showBinDrawer: false,
    showSearchModal: false,
    activeInfoPopIndex: null,
    currentDock: null,
    siteModalOpen: null,
    siteModalData: null,
    updateDockFromOutside: null,
    keepSidebarOpen: false,
    zoomXY: null,
    showFeedbackModal: false
};

const layoutStore = create<Store>(
    (set, get): Store => ({
        ...initialState,
        setHoverSide: (hoverSide: boolean) => {
            set({ hoverSide: hoverSide });
        },
        setWorkspaceZoom: (workspaceZoom: string) => {
            set({ workspaceZoom: workspaceZoom });
        },
        setSidePanelIsOpen: (sidePanelIsOpen: boolean) => {
            set({ sidePanelIsOpen: sidePanelIsOpen });
        },
        setNoteEditMode: (noteEditMode: string) => {
            if (noteEditMode === null) {
                set({ noteEditMode: null, currentDock: null });
            } else {
                useSelectStore.getState().clearSelection();
                set({ noteEditMode: noteEditMode, currentDock: 'noteToolbar' });
            }
        },
        setBlockScaleUp: (status: boolean) => {
            set({ blockScaleUp: status });
        },
        setBookmarksModal: (showBookmarksModal: boolean) => {
            set({ showBookmarksModal: showBookmarksModal });
        },
        setCatModal: (showCatModal: boolean) => {
            set({ showCatModal: showCatModal });
        },
        setIsZooming: (newStatus: boolean) => {
            set({ isZooming: newStatus });
        },
        setPinSidebar: (newStatus: boolean) => {
            set({ sidebarIsPinned: newStatus });
        },
        setEmojiPickerOpen: (emojiPickerOpen: string) => {
            set({ emojiPickerOpen: emojiPickerOpen });
        },
        setShowLimitModal: (showLimitModal: boolean) => {
            set({ showLimitModal: showLimitModal });
        },
        setShowShareModal: (showShareModal: boolean, id = null) => {
            setShowShareModalAction(set, get, showShareModal, id);
        },
        setNewlyAddedGroup: (newlyAddedGroup: string[]) => {
            set({ newlyAddedGroup: newlyAddedGroup });
        },
        setShowBinDrawer: (showBinModal: boolean) => {
            set({ showBinDrawer: showBinModal });
        },
        setShowSearchModal: (showSearchModal: boolean) => {
            set({ showSearchModal: showSearchModal });
        },
        setActiveInfoPopIndex: (index: number) => {
            set({ activeInfoPopIndex: index });
        },
        showDock: (dockType: dockTypes) => {
            set({ currentDock: dockType });
        },
        setSiteModalOpen: (data: TabModalData) => {
            setSiteModalOpenAction(set, get, data);
        },
        updateInputValue: null,
        setUpdateDockFromOutside: (value: UpdateDockType) => {
            set({ updateDockFromOutside: value });
        },
        setZoomXY: (x: number, y: number) => {
            set({ zoomXY: { x: x, y: y } });
        },
        setShowFeedbackModal: (showFeedbackModal: boolean) => {
            set({ showFeedbackModal: showFeedbackModal });
        }
    })
);

const setSiteModalOpenAction = (set, get, data: TabModalData) => {
    useStore.setState({ cachedGroups: {} });
    useSelectStore.getState().clearSelection();
    if (data !== null) {
        set({ siteModalOpen: data.id, siteModalData: data });
    } else {
        //close modal
        set({ siteModalOpen: null, siteModalData: null });
    }
};

const setShowShareModalAction = async (
    set,
    get,
    showShareModal: boolean,
    id = null
) => {
    if (showShareModal) {
        let roles;
        let workspacesInUser = useStore.getState().userData.workspaces;
        let workspaceInUser = workspacesInUser.find(
            (workspace) => workspace.id === id
        );
        let shareModalData = {
            name: workspaceInUser.name,
            emoji: workspaceInUser.emoji,
            catLength: workspaceInUser.catData?.length,
            id: id,
            public: workspaceInUser.public
        };
        if (id === useStore.getState().currentWsId) {
            shareModalData['isOwner'] =
                useStore.getState().wsOwner ===
                useStore.getState().userData.userId;
            shareModalData['roles'] = useStore.getState().wsRoles;
            shareModalData['loadingWsData'] = false;
            set({
                showShareModal: showShareModal,
                shareModalData: shareModalData
            });
        } else {
            shareModalData['loadingWsData'] = true;
            set({
                showShareModal: showShareModal,
                shareModalData: shareModalData
            });
            fetchWsRoles(id).then((res) => {
                if (res?.msg === 'error') {
                    showToast(
                        'Error fetching workspace data',
                        'Please try again',
                        'error'
                    );
                    console.error(
                        'Error fetching workspace data - share modal'
                    );
                } else {
                    let roles = res?.roles ? res?.roles : [];
                    if (res.roles) {
                        let owner = res.roles.find(
                            (role) => role.status === 'owner'
                        );
                        if (owner) {
                            shareModalData['isOwner'] =
                                owner.userId ===
                                useStore.getState().userData.userId;
                        } else {
                            shareModalData['isOwner'] = true;
                        }
                    } else {
                        shareModalData['isOwner'] = true;
                    }

                    shareModalData['roles'] = roles;
                    shareModalData['loadingWsData'] = false;

                    if (get().shareModalData.id === id) {
                        set({
                            shareModalData: structuredClone(shareModalData)
                        });
                    }
                }
            });
        }
    } else {
        set({
            showShareModal: showShareModal,
            shareModalData: null
        });
    }
};

export default layoutStore;
