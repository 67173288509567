import useStore from '../../sharedCode/stores/store';
import useDndStore from '../../sharedCode/stores/dndStore';
import {
    addTabsToGroup,
    closeTabs,
    createTabGroupAndMove,
    moveGroups,
    moveTabs,
    queryActiveTabs,
    removeTabFromGroup
} from '../browserAPI';
import { v4 as uuidv4 } from 'uuid';
import { checkFavIconLength } from '../../sharedCode/Utils/favIconUtils';
import { findGroup } from '../../sharedCode/Utils/findDataFunctions';
import layoutStore from '../../sharedCode/stores/layoutStore';
import { showToast } from '../../sharedCode/Utils/showToastWrapper';
import {
    addGroupWithTabs,
    stackItems
} from '../../sharedCode/components/dnd/handleTabMove';
import { isTrialReached } from '../../sharedCode/components/userApi';

const handleActiveGroupMove = async (
    active,
    activeTabs,
    activeTabIndex,
    insertPosition,
    overContainerZu,
    originalContinerId
) => {
    const [overItemId, position] = Object.entries(insertPosition).flat();

    let tabGroup = activeTabs[activeTabIndex];
    let title = tabGroup.title;
    let emoji = tabGroup.emoji;
    let tabIdsToClose = [];

    if (overContainerZu === 'ContainerPlaceholder') {
        if (isTrialReached()) {
            return;
        }
        let tabGroups = structuredClone(useStore.getState().tabGroups);
        let tabsToAdd = [];
        tabGroup.children.forEach((activeTab) => {
            tabIdsToClose.push(activeTab.id);
            let persistTab = convertActiveTabToPersistedTab(activeTab);
            tabsToAdd.push(persistTab);
        });
        useDndStore.getState().setNewDragEndId(active.id, tabsToAdd[0].id);

        let resp = await addGroupWithTabs(tabGroups, tabsToAdd, title, emoji);
        if (resp.msg === 'success') {
            closeTabs(tabIdsToClose, originalContinerId, false, true);
        } else {
            // useStore.getState().setActiveTabsInWindow(fallbackActiveTabs, originalContinerId);
        }
        return;
    } else if (originalContinerId === overContainerZu) {
        let movedToTabIndex = null;
        const result = findItemIndex(activeTabs, overItemId);
        if (result.groupIndex || result.groupIndex === 0) {
            showToast(
                'Drag not allowed',
                'Can not move a group into another group',
                'error'
            );
            return;
        } else {
            movedToTabIndex = activeTabs[result.index].tabIndex;

            let newIndex = result.index;
            if (position === 'under') {
                newIndex++;
            }
            // activeTabs.splice(newIndex, 0, ...movedItems);
            // await moveTabs(dragIds, -1);

            // moveTabs(dragIds, movedToTabIndex, null);
        }
        if (position === 'center') {
            showToast(
                'Drag not allowed',
                'Can not drop tab group on another active tab',
                'error'
            );
            return;
        }

        await moveGroups(active.id, movedToTabIndex);
        queryActiveTabs();
        useStore
            .getState()
            .setActiveTabsInWindow(activeTabs, originalContinerId);
    } else {
        if (isTrialReached()) {
            return;
        }

        let tabGroups = structuredClone(useStore.getState().tabGroups);
        let tabsToAdd = [];
        tabGroup.children.forEach((activeTab) => {
            tabIdsToClose.push(activeTab.id);
            let persistTab = convertActiveTabToPersistedTab(activeTab);
            tabsToAdd.push(persistTab);
        });
        let overContainer = findGroup(tabGroups, overContainerZu);
        let insertIndex = 0;
        if (overContainer.tabs.length > 0) {
            insertIndex = overContainer.tabs.findIndex(
                (tab) => tab.id === overItemId
            );
            if (position === 'under') {
                insertIndex++;
            }
        }
        if (position === 'center') {
            if (!overContainer.tabs[insertIndex].comment.length) {
                let newStack = stackItems(
                    overContainer.tabs[insertIndex],
                    tabsToAdd
                );
                useDndStore.getState().setNewDragEndId(active.id, newStack.id);

                overContainer.tabs[insertIndex] = newStack;
            } else {
                showToast(
                    'Drag not allowed',
                    'Can only merge sites from active window with sites',
                    'warning'
                );
                return;
            }
        } else {
            let newId = uuidv4();
            const dateCreated = new Date().toString();

            let newStack = {
                id: newId,
                isStacked: true,
                stackedItems: tabsToAdd,
                dateCreated: dateCreated,
                comment: '',
                commentColor: false,
                title: title,
                emoji: emoji
            };
            useDndStore.getState().setNewDragEndId(active.id, newStack.id);

            overContainer.tabs.splice(insertIndex, 0, newStack);
        }
        let resp = await useStore.getState().updateTabGroups(tabGroups, {
            type: 'groups',
            ids: [overContainerZu]
        });

        !layoutStore.getState().sidebarIsPinned &&
            layoutStore.getState().setHoverSide(false);
        if (resp.msg === 'success') {
            showToast(
                'Tabs saved successfully',
                'Closing active tabs',
                'success'
            );
            closeTabs(tabIdsToClose, originalContinerId, false, true);
        }
    }
};

export const handleActiveTabMove = async (active) => {
    let { insertPosition, overContainerZu } = useDndStore.getState();
    let originalContinerId = active.data.current.groupId;

    // console.log('overContainerZu drag end', overContainerZu);
    let activeTabs = structuredClone(
        useStore.getState().allActiveTabs[originalContinerId].activeTabs
    );

    let activeTabIndex = activeTabs.findIndex((tab) => tab.id === active.id);
    const [overItemId, position] = Object.entries(insertPosition).flat();

    if (activeTabIndex !== -1 && activeTabs[activeTabIndex].children) {
        handleActiveGroupMove(
            active,
            activeTabs,
            activeTabIndex,
            insertPosition,
            overContainerZu,
            originalContinerId
        );
        return;
    }

    let dragIds = useDndStore.getState().dragIds;

    const { filteredActiveTabs, movedItems, tabMoveIndexes } =
        filterDraggedItems(dragIds, activeTabs);
    activeTabs = filteredActiveTabs;
    let movedToTabIndex = null;
    if (overContainerZu === 'ContainerPlaceholder') {
        if (isTrialReached()) {
            return;
        }
        let tabGroups = structuredClone(useStore.getState().tabGroups);

        let tabsToAdd = [];

        movedItems.forEach((activeTab) => {
            let persistTab = convertActiveTabToPersistedTab(activeTab);
            useDndStore.getState().setNewDragEndId(activeTab.id, persistTab.id);
            tabsToAdd.push(persistTab);
        });

        addGroupWithTabs(tabGroups, tabsToAdd).then((resp) => {
            if (resp.msg === 'success') {
                closeTabs(dragIds, originalContinerId, false, true);
            } else {
                // useStore
                //     .getState()
                //     .setActiveTabsInWindow(
                //         fallbackActiveTabs,
                //         originalContinerId
                //     );
            }
        });

        return;
    } else if (originalContinerId === overContainerZu) {
        const result = findItemIndex(activeTabs, overItemId);

        if (result.groupIndex || result.groupIndex === 0) {
            movedToTabIndex =
                activeTabs[result.groupIndex].children[result.indexInsideGroup]
                    .tabIndex;

            let newIndex = result.indexInsideGroup;
            if (newIndex === 0) {
                addTabsToGroup(
                    activeTabs[result.groupIndex].id,
                    dragIds,
                    false
                );
            }

            if (position === 'under') {
                newIndex++;
            }
            if (newIndex === activeTabs[result.groupIndex].children.length) {
                activeTabs.splice(result.groupIndex + 1, 0, ...movedItems);
            } else {
                activeTabs[result.groupIndex].children.splice(
                    newIndex,
                    0,
                    ...movedItems
                );
            }

            // moveTabs(dragIds, movedToTabIndex, null, result.groupIndex);
        } else {
            let overTab = activeTabs[result.index];
            if (position === 'center') {
                createTabGroupAndMove(
                    [...dragIds, overTab.id],
                    overTab.tabIndex
                );
                return;
            }
            if (overTab.children) {
                if (position !== 'under') {
                    addTabsToGroup(overTab.id, dragIds, true);
                    return;

                    // movedToTabIndex = overTab.tabIndex;
                } else {
                    let dragIdsToNum = dragIds.map((id) => Number(id));
                    removeTabFromGroup(dragIdsToNum);
                    return;
                }
            }
            movedToTabIndex = overTab.tabIndex;

            let newIndex = result.index;
            if (position === 'under') {
                newIndex++;
            }
            activeTabs.splice(newIndex, 0, ...movedItems);
            // await moveTabs(dragIds, -1);

            // moveTabs(dragIds, movedToTabIndex, null);
        }
        // console.log('reorder tabs');
        // const dragIdsAsNumbers = dragIds.map((id) => Number(id));
        // moveTabs(dragIds,movedToTabIndex, null, )
        // console.log('move to tab index', movedToTabIndex);
        tabMoveIndexes.sort((a, b) => a.originalIndex - b.originalIndex);
        if (tabMoveIndexes[0].index > movedToTabIndex && position === 'under') {
            movedToTabIndex++;
        } else if (
            tabMoveIndexes[tabMoveIndexes.length - 1].index < movedToTabIndex &&
            position === 'over'
        ) {
            movedToTabIndex--;
        }
        tabMoveIndexes.forEach((item) => {});
        useStore
            .getState()
            .setActiveTabsInWindow(activeTabs, originalContinerId);
        // chrome.tabs.move(dragIdsAsNumbers, { index: movedToTabIndex });
    } else {
        if (isTrialReached()) {
            return;
        }
        let tabGroups = structuredClone(useStore.getState().tabGroups);
        let fallbackState = structuredClone(tabGroups);
        let tabsToAdd = [];
        movedItems.forEach((activeTab) => {
            let persistTab = convertActiveTabToPersistedTab(activeTab);
            useDndStore.getState().setNewDragEndId(activeTab.id, persistTab.id);
            tabsToAdd.push(persistTab);
        });
        let overContainer = findGroup(tabGroups, overContainerZu);
        let insertIndex = 0;
        if (overContainer.tabs.length > 0) {
            insertIndex = overContainer.tabs.findIndex(
                (tab) => tab.id === overItemId
            );
            if (position === 'under') {
                insertIndex++;
            }
        }
        if (position === 'center') {
            if (!overContainer.tabs[insertIndex].comment.length) {
                let newStack = stackItems(
                    overContainer.tabs[insertIndex],
                    tabsToAdd
                );
                overContainer.tabs[insertIndex] = newStack;
            } else {
                showToast(
                    'Drag not allowed',
                    'Can only merge sites from active window with sites',
                    'warning'
                );
                return;
            }
        } else {
            overContainer.tabs.splice(insertIndex, 0, ...tabsToAdd);
        }
        useStore
            .getState()
            .updateTabGroups(tabGroups, {
                type: 'groups',
                ids: [overContainerZu]
            })
            .then((resp) => {
                if (resp.msg === 'success') {
                    if (dragIds.length > 1) {
                        showToast(
                            'Tabs saved successfully',
                            'Closing active tabs',
                            'success'
                        );
                    } else {
                        showToast(
                            'Tab saved successfully',
                            'Closing active tab',
                            'success'
                        );
                    }

                    closeTabs(dragIds, originalContinerId, false, true);
                } else {
                    showToast(
                        'Error updating database on drag save',
                        'Tabs restored, please try again',
                        'error'
                    );
                    // useStore
                    //     .getState()
                    //     .setActiveTabsInWindow(
                    //         fallbackActiveTabs,
                    //         originalContinerId
                    //     );

                    useStore
                        .getState()
                        .updateTabGroups(
                            fallbackState,
                            { type: 'items', ids: [] },
                            false
                        );
                }
            }); // TODO fixa id
        !layoutStore.getState().sidebarIsPinned &&
            layoutStore.getState().setHoverSide(false);
    }
    // useStore.getState().setActiveTabsInWindow(activeTabs, originalContinerId);
};

export const convertActiveTabToPersistedTab = (activeTab) => {
    let newId = uuidv4();
    let dateCreated = new Date().toString();

    return {
        id: newId,
        title: activeTab.title,
        favIcon: checkFavIconLength(activeTab.favIcon, activeTab.url),
        url: activeTab.url,
        comment: '',
        commentColor: false,
        note: false,
        dateCreated: dateCreated
    };
};

function findItemIndex(activeTabs, itemId) {
    // Loop through the activeTabs array to find the item or group containing the item
    for (let i = 0; i < activeTabs.length; i++) {
        const item = activeTabs[i];
        // Check if the current item is the one we're looking for
        if (item.id === itemId) {
            return { index: i }; // Item found at the top level
        }
        // If the item is a group, search its children
        if (item.children && item.children.length) {
            for (let j = 0; j < item.children.length; j++) {
                if (item.children[j].id === itemId) {
                    return { groupIndex: i, indexInsideGroup: j }; // Item found inside a group
                }
            }
        }
    }
    // Item not found
    return null;
}

export const filterDraggedItems = (dragIds, items) => {
    let removedItems = [];
    let tabMoveIndexes = [];

    const filteredItems = items.reduce((acc, item) => {
        if (item.children && item.children.length) {
            const filteredChildren = filterDraggedItems(dragIds, item.children);
            if (!dragIds.includes(item.id)) {
                acc.push({
                    ...item,
                    children: filteredChildren.filteredActiveTabs
                });
            } else {
                removedItems = removedItems.concat(item);
            }
            removedItems = removedItems.concat(filteredChildren.movedItems);
            filteredChildren.movedItems.forEach((item) => {
                tabMoveIndexes.push({
                    id: item.id,
                    index: item.tabIndex
                });
            });
        } else if (!dragIds.includes(item.id)) {
            acc.push(item);
        } else {
            removedItems.push(item);
            tabMoveIndexes.push({
                id: item.id,
                index: item.tabIndex
            });
        }
        return acc;
    }, []);

    return {
        filteredActiveTabs: filteredItems,
        movedItems: removedItems,
        tabMoveIndexes: tabMoveIndexes
    };
};
